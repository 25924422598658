import { add, format } from 'date-fns';

export const formatEta = (eta: Date, creation: Date) => {
  const dateEta = new Date(eta);
  const dateCreated = new Date(creation);
  const MS_IN_MINUTE = 60000;
  const prevDate =
    dateEta.getTime() - dateCreated.getTime() <= 15 * MS_IN_MINUTE
      ? format(dateCreated, 'H:mm')
      : format(add(dateEta, { minutes: -15 }), 'H:mm');

  const nextDate =
    dateEta.getTime() - dateCreated.getTime() <= 15 * MS_IN_MINUTE
      ? format(add(dateCreated, { minutes: 30 }), 'H:mm')
      : format(add(dateEta, { minutes: 15 }), 'H:mm');

  const restDate = format(dateEta, 'dd/MM');
  const isToday = format(dateEta, 'dd/MM') === format(new Date(), 'dd/MM');
  const dia = isToday ? 'hoje' : `dia ${restDate}`;
  return ` ${dia} entre ${prevDate} e ${nextDate}`;
};

export const formatCarrierEta = (eta: Date) => {
  const dateEta = new Date(eta);

  return format(dateEta, 'dd/MM');
};
